import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Container from "@mui/material/Container";
import Logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
export default function Intro() {
  const navigate = useNavigate();
  function onClickNext() {
    localStorage.setItem("is_seen", true);
    navigate("/auth/login", { replace: true });
  }
  return (
    <Container>
      {" "}
      <Card>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} width={60} />
          <Typography gutterBottom variant="h5" component="div">
            Introduction
          </Typography>
          <Typography
            sx={{
              maxHeight: "63vh",
              overflowY: "scroll",
            }}
            variant="body2"
            color="text.secondary"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et
            enim metus. Mauris consectetur elit in tellus elementum tristique.
            Vestibulum tortor arcu, sagittis quis ullamcorper a, feugiat in
            tellus. In convallis urna risus, sit amet ullamcorper sem
            pellentesque sit amet. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Nam convallis ipsum dui, hendrerit tempor risus
            ultrices imperdiet. Morbi eros ex, pretium non dolor non, hendrerit
            accumsan tellus. Integer pharetra dui libero, sed egestas erat
            mollis nec. Nulla vulputate pretium elit, nec rhoncus arcu venenatis
            non. Nullam vel elit sollicitudin, mattis ligula in, aliquam massa.
            Sed aliquam arcu et nulla posuere, in rhoncus magna pulvinar. Fusce
            suscipit consequat maximus. Nunc eros mi, cursus vitae consectetur
            vel, accumsan scelerisque arcu. Sed feugiat ipsum quis metus congue,
            in suscipit mauris ultricies. Praesent aliquet eros sed aliquet
            ullamcorper. Sed nec suscipit felis, sit amet commodo ex. Sed quis
            arcu cursus, dignissim lacus in, porta mi. Morbi porta turpis
            maximus nisl ullamcorper fermentum. Suspendisse potenti. Aenean
            tincidunt est vel sapien egestas, ac commodo orci luctus.
            Pellentesque rutrum aliquet turpis, non venenatis nunc commodo
            vitae. Nullam pharetra, orci eu consectetur accumsan, nunc metus
            consequat sem, et suscipit nunc augue quis enim. Sed aliquet
            suscipit iaculis. Quisque dapibus libero sed pellentesque tincidunt.
            Aliquam volutpat sem ut diam lobortis, eu sagittis leo fermentum.
            Fusce congue vestibulum fringilla. Fusce tincidunt massa id
            hendrerit sagittis. Nunc egestas mauris bibendum feugiat
            ullamcorper. Duis sagittis, justo ac ornare euismod, urna nunc
            ornare lorem, id dignissim dolor quam vel magna. Curabitur vitae
            sapien in leo imperdiet fermentum. Curabitur id est nulla. Sed
            tristique facilisis ultricies. Mauris ultrices eleifend lorem sit
            amet congue. Maecenas non magna imperdiet purus sollicitudin
            finibus. In tristique purus purus. Fusce ut ipsum sollicitudin,
            imperdiet lacus id, lobortis purus. Proin blandit eleifend turpis,
            et tincidunt sem vulputate vel. Nullam aliquet, mauris non rhoncus
            aliquet, velit velit dapibus enim, sed posuere leo dui quis elit.
            Sed tristique mi a tellus semper dapibus. Nam fermentum nibh sed
            nisi pharetra egestas. Integer dictum, ante vitae aliquet euismod,
            augue tellus semper felis, et dapibus sapien enim quis eros. Donec
            posuere non enim in efficitur. In eu dui non mi venenatis egestas ut
            nec massa. Suspendisse potenti. In convallis, enim interdum accumsan
            vulputate, turpis arcu ultrices nibh, vel luctus sapien enim vitae
            lorem. Sed non tempor sem. Mauris ex mauris, dignissim sit amet
            fringilla at, luctus vulputate nisi. Nam tristique sem vitae semper
            commodo. Cras a risus quis ligula semper volutpat eget in nibh.
            Donec metus est, placerat sed consequat in, placerat vel metus.
            Maecenas ullamcorper gravida justo quis placerat. Etiam turpis diam,
            egestas lobortis tempus nec, rutrum sit amet dui. Class aptent
            taciti sociosqu ad litora torquent per conubia nostra, per inceptos
            himenaeos. Pellentesque ullamcorper maximus mi sed molestie. Integer
            placerat sed odio vitae semper. Morbi tempus turpis nec mi rutrum
            molestie. Donec blandit scelerisque felis ac rhoncus. Fusce sed
            ornare quam. Etiam nec tincidunt augue. Nunc a dictum arcu. In
            condimentum, orci vel pellentesque porttitor, quam tortor mollis
            neque, vel mollis purus nibh vitae enim. Nullam non ullamcorper
            eros. Pellentesque semper ligula metus, vel viverra nunc maximus ac.
            Integer orci diam, commodo vel libero vel, ultrices tempus justo.
            Curabitur vitae purus sit amet est vehicula ultrices. Nam tincidunt
            erat non fermentum mattis. Cras at vehicula eros. Praesent
            sollicitudin justo et justo volutpat consequat. Nam fermentum
            laoreet magna. In vitae lacus erat. Proin ut felis vel nunc iaculis
            dapibus in finibus eros. Praesent tincidunt imperdiet euismod. Ut in
            elit massa. Donec venenatis tristique enim, ac dapibus orci
            scelerisque eget. Suspendisse aliquam, ipsum sed pharetra suscipit,
            arcu risus convallis justo, in euismod ligula quam eu massa. Ut enim
            eros, vestibulum quis malesuada at, consectetur sed arcu. Morbi ut
            risus varius erat tristique commodo eget sit amet elit. Nulla nec
            tempus orci, sed volutpat augue. Donec faucibus est quis ultrices
            porttitor. Donec nec accumsan nunc. Mauris ut nisl semper, efficitur
            velit dapibus, aliquam neque. In tincidunt semper ultricies. Fusce
            lacus sapien, iaculis et euismod eget, blandit nec mi. Mauris
            euismod purus ut nunc egestas gravida. Etiam faucibus ultricies
            pulvinar. Donec eu gravida tellus. Praesent vel elit felis. Aliquam
            at suscipit mi. Fusce malesuada nisl mi, quis condimentum nulla
            commodo a. Nunc eu lacus tellus. Nulla eget commodo orci. Curabitur
            vitae metus nec sem gravida scelerisque et ac massa. Proin gravida
            arcu placerat felis efficitur, eu hendrerit dolor commodo. Duis dui
            mauris, consectetur nec faucibus et, dignissim eget mi. Ut justo
            nibh, dignissim id bibendum id, efficitur eu felis. Curabitur sed
            tortor sagittis, vestibulum tellus eu, egestas sem. Cras eu tortor
            pharetra, viverra lorem id, blandit lorem. Donec feugiat ut risus ut
            mollis. Etiam elementum molestie mollis. Vivamus egestas ultricies
            volutpat. Sed fermentum viverra nibh, ac sodales urna rutrum et.
            Proin sed urna ante. Nulla venenatis tristique est sed lacinia. Duis
            ultricies turpis vitae purus dictum, in blandit orci condimentum.
            Suspendisse potenti. Pellentesque maximus velit id ante lacinia,
            quis elementum nisl semper. Vestibulum auctor ipsum massa, sed
            viverra augue dictum eu. Praesent pretium tristique tempus. Aenean
            posuere orci at velit consequat faucibus. Mauris fermentum nulla
            vitae arcu convallis auctor. Ut pulvinar lobortis est vitae mollis.
            Quisque viverra elementum fringilla. Vestibulum ante ipsum primis in
            faucibus orci luctus et ultrices posuere cubilia curae; Praesent
            eget arcu urna. Quisque facilisis odio id tortor pellentesque, at
            dignissim neque euismod.
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => onClickNext()} size="large" color="primary">
            Next
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
