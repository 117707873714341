import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Input,
  TextField,
  ThemeProvider,
} from '@mui/material'
import './App.css'
import theme from './theme/ThemeProvider'
import Logo from './assets/img/logo.png'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import axiosConfig from './service/axios.config'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Navigate } from 'react-router-dom'
function App() {
  const [success, setSuccess] = useState({ show: false, message: '' })
  const [error, setError] = useState({ show: false, message: '' })
  const [showPassword, setShowPassword] = useState(false)
  let isSeenLandingPage = localStorage.getItem('is_seen')

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const loginFormik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validate: ({ login, password }) => {
      const error = {}
      if (!login) error.login = 'please fill every details'
      if (!password) error.password = 'please fill every details'
      return error
    },
    onSubmit: async ({ login, password }) => {
      axiosConfig
        .post('/api/auth/login?access=web', {
          login,
          password,
        })
        .then((response) => {
          setError({ show: false, message: '' })
          setSuccess({ show: true, message: response.data.message })
          setTimeout(() => {
            window.location.replace('/')
          }, 500)
        })
        .catch((error) => {
          console.log(error)
          setError({ show: true, message: error.response.data.message })
        })
    },
  })

  useEffect(() => {
    window.addEventListener('keypress', function (e) {
      if (e.key == 'Enter') {
        loginFormik.submitForm()
      }
    })

    return () => {
      window.removeEventListener('keypress', (e) => {})
    }
  }, [])

  if (!isSeenLandingPage) {
    return <Navigate to={'/landing'} replace={true} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {success.show && (
          <Alert
            sx={{
              marginBottom: '1rem',
            }}
            severity="success"
          >
            <AlertTitle>Success</AlertTitle>
            <strong>{success.message}</strong>
          </Alert>
        )}
        {error.show && (
          <Alert
            action={
              <Button
                onClick={() => setError({ show: false, message: '' })}
                sx={{ color: 'white' }}
              >
                close
              </Button>
            }
            sx={{
              marginBottom: '1rem',
            }}
            variant="filled"
            severity="error"
          >
            <strong>{error.message}</strong>
          </Alert>
        )}

        <Box
          display="flex"
          flexDirection="column"
          padding="3rem"
          alignItems="center"
          sx={{
            width: 500,
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 5px #a3a3a3',
          }}
        >
          <img src={Logo} width={200} />
          <TextField
            sx={{
              margin: '.7rem 0',
            }}
            name="login"
            error={loginFormik.errors.login}
            value={loginFormik.values.login}
            onChange={loginFormik.handleChange}
            fullWidth={true}
            label="Username"
            variant="outlined"
          />
          <TextField
            sx={{
              margin: '.7rem 0',
            }}
            onChange={loginFormik.handleChange}
            name="password"
            error={loginFormik.errors.password}
            type={showPassword ? 'text' : 'password'}
            value={loginFormik.values.password}
            fullWidth={true}
            label="Password"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="button"
            variant="contained"
            onClick={() => loginFormik.submitForm()}
            sx={{
              width: 150,
              backgroundColor: '#282828',
              color: 'white',
            }}
          >
            Login
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default App
