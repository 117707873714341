import { Route, Routes, BrowserRouter } from "react-router-dom";
import App from "../App";
import Intro from "../components/Intro";

export default function LayoutRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {["/auth/login", "/","*"].map((e) => (
          <Route key={e} path={e} Component={App} />
        ))}

        <Route path="/landing" Component={Intro} />
      </Routes>
    </BrowserRouter>
  );
}
