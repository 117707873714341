import { createTheme } from "@mui/material";

const theme=createTheme({
    palette:{
        primary:{
            main:"#000000"
        }
    }
})

export default theme